import ArrowDown from "@impulso/common/Icons/ArrowDown";
import ArrowUp from "@impulso/common/Icons/ArrowUp";
import { ReactNode, useState } from "react";

export default function ReportFoldout(props: {title: string, children?: ReactNode}) {
    const [foldout, setFoldout] = useState(false);

    return (
        <div className="col-span-12 desktop-plus:col-span-18 border">
            <div className={"p-4 flex gap-4 hover:cursor-pointer hover:bg-gray-100" + (foldout ? ' border-b !bg-gray-200' : '')} onClick={() => setFoldout(!foldout)}>
                {foldout ? <ArrowUp width="16px" /> : <ArrowDown width="16px" />}
                <p className="font-semibold select-none w-max">{props.title}</p>
            </div>
            { foldout ?
                <div className="relative">
                    {props.children}
                </div> : <></>
            }
        </div>
    );
}