import CheckMark from "@impulso/common/Icons/CheckMark";
import XMark from "@impulso/common/Icons/XMark";
import { Loader } from "@mantine/core";
import { SerializedError } from "@reduxjs/toolkit";
import { QueryStatus, FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { OrganisationEndpointResponse } from "src/api/OrganisationApi";


export function CreateBanner(props: {data?: OrganisationEndpointResponse<any>, status?: QueryStatus, isError: boolean, error?: FetchBaseQueryError | SerializedError, successMsg?: string}) {

    if (props.status === QueryStatus.pending)
        return <LoadingBanner text="Loading, please wait..." />
    
    if (props.isError)
        return <ErrorBanner errorText="Failed to complete action." reason={props.error ? getErrorMessage(props.error) : undefined} />

    if (props.status === QueryStatus.fulfilled && props.data === undefined)
        return <SuccessBanner content={props.successMsg} />

    if (props.data === undefined)
        return <></>;
    
    const errorText = props.data.error == null ? typeof props.data.content == 'object' ? "Content was object? Cannot display." : props.data.content : props.data.error;
    
    switch(props.data.status) {
        case "FAILED":
            return <ErrorBanner errorText="Failed to complete action." reason={errorText} />
        case "SUCCESS":
            return <SuccessBanner content={errorText} />
    }

    return <></>;
}

function SuccessBanner(props: {content?: string, organisationId?: string}) {
    return (
        <div className="bg-confirmation-100 p-4 flex gap-4 items-center">
            <CheckMark />
            <p className="font-semibold">{props.content}</p>
            {/* <p className="italic text-gray-400 ml-auto">ID: {props.organisationId}</p> */}
        </div>
    );
}

function LoadingBanner(props: {text: string}) {
    return (
        <div className="bg-warning-100 p-4 flex gap-4">
            <Loader size={'xs'} />
            <p>{props.text}</p>
        </div>
    );
}

function ErrorBanner(props: {errorText: string, reason?: string}) {
    return (
        <div className="bg-error-100 p-4 flex gap-4 items-center">
            <div className="min-w-4"><XMark /></div>
            <p className="whitespace-nowrap">{props.errorText}</p>
            {props.reason !== undefined ? <p className="ml-auto text-right w-full">Reason: <span className="font-semibold">{props.reason}</span></p> : <></>}
        </div>
    );
}

function isSerializedError(error: FetchBaseQueryError | SerializedError): error is SerializedError {
    return (error as SerializedError).message !== undefined;
}

function getErrorMessage(error: FetchBaseQueryError | SerializedError) {
    if (isSerializedError(error)) {
        return error.message;
    }
     
    if (typeof error.data === "string") {
        return error.data;
    }

    if (typeof error.status === "number") {
        if (!error.data || typeof error.data !== "object") {
            return "Undefined error.";
        }
        if ("error" in error.data && typeof error.data.error === "string") {
            return error.data.error;
        }
        return "Undefined error."
    }

    if ('error' in error) {
        return error.error;
    }

    return "Undefined error.";
}