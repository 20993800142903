import {useAuth0} from "@auth0/auth0-react";
import {useSelector} from "react-redux";
import { RootState } from "../Store";
import { GlobalAppsettings } from "./GlobalSettings";

export const useGlobalSecurity = () => {
  const { loginWithRedirect, logout } = useAuth0();
  const accessToken = useSelector((s: RootState) => s.user.token);

  return {
    accessToken,
    login: loginWithRedirect,
    logout: () => logout({ returnTo: GlobalAppsettings.LogoutUri })
  }
}