import Plus from "@impulso/common/Icons/Plus";
import {Page} from "@impulso/common/components/Page";
import {PrimaryButton} from "@impulso/common/components/buttons/PrimaryButton";
import {TableReportColumn} from "@impulso/common/components/report/DataTable";
import {TableReport} from "@impulso/common/components/report/TableReport";
import {parse} from "date-fns";
import {useMemo, useState} from "react";
import {useNavigate} from "react-router";
import {AgreementResponse, VolumeFeeStep, useGetAgreementsQuery} from "src/api/AgreementApi";
import Paths from "src/configuration/Paths";
import {generalFilter, IFilterItem} from "@impulso/common/filters/generalFilter";
import SearchBar, {SearchResult} from "@impulso/common/components/searchBar/SearchBar";
import Download from "@impulso/common/Icons/Download";
import CustomSelectElement from "@impulso/common/components/buttons/CustomDropdown"
import Folder from "@impulso/common/Icons/Folder";
import {downloadAgreementAttachment} from "src/api/DownloadApi";
import {useGlobalSecurity} from "src/common/UseGlobalSecurity";
import addDays from "date-fns/addDays";
import format from "date-fns/format";
import {ProductTrackerFeePlan} from "src/pages/agreements/FeePlan";
import {SimpleBadge} from "@impulso/common/components/Badge";


type AgreementFilterKey = 'partner' | 'status';
type AgreementSearchResult = SearchResult<AgreementFilterKey>;

const defaultFilterData: Record<AgreementFilterKey, IFilterItem[]> = {
    'status': [],
    'partner': [],
}

type Agreement = AgreementResponse & {
    status: string;
};

const parseDate = (s: string) => parse(s, "yyyy-MM-dd", new Date());

function getStatus(now: Date, agreement: AgreementResponse) {
    const startDate = parseDate(agreement.startDate);
    const endDate = parseDate(agreement.endDate);
    if (now > endDate) {
        return "Ended";
    }

    if (now < startDate) {
        return "Pending";
    }

    return "Active";
}

function onlyUnique(value: string, index: number, array: string[]) {
    return array.indexOf(value) === index;
}

export function Agreements() {
    const {data, isFetching, isError} = useGetAgreementsQuery({});
    const {accessToken} = useGlobalSecurity();

    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState(generalFilter(defaultFilterData));

    const rows = data?.map(a => {
        const now = new Date();
        return {
            ...a,
            status: getStatus(now, a)
        } as Agreement;
    });

    const columns = useMemo(() => GetColumns(), []);

    const filterSearch = (categoryName: AgreementFilterKey) =>
        (value: string) => search === categoryName || value.toLowerCase().startsWith(search.toLowerCase());

    const searchResults: AgreementSearchResult = {
        partner: rows?.map(r => r.partnerName).filter(filterSearch("partner")).filter(onlyUnique).map(name => ({
            label: name,
            value: name
        })) ?? [],
        status: ["Ended", "Pending", "Active"].filter(filterSearch("status")).map(s => ({label: s, value: s}))
    };

    const filteredRows = rows?.filter(row =>
        filter.validate("partner", row.partnerName) && filter.validate("status", row.status)
    );

    const onDownload = (fileName: string, fileId: string) => {
        downloadAgreementAttachment(accessToken!, fileName, fileId);
    }

    if (isError) {
        return <Page hasAccess={true} titleKey="agreements.title">
            <div>Failed to load agreements</div>
        </Page>;
    }

    return (
        <Page responsive hasAccess={true} titleKey="agreements.title" rightAction={<CreateButton/>}>
            <div className="mt-4"/>
            <SearchBar
                filter={filter}
                setFilter={setFilter}
                searchString={search}
                setSearch={setSearch}
                searchResult={searchResults ?? defaultFilterData}
                loading={false}
                minSearchLength={1}
                placeholder="Search for partner or status"
                id="admin-agreement-searchbar"
            />
            <div className="mt-4"/>
            <TableReport
                rowBody={agreement => <AgreementBody agreement={agreement} onGetAttachment={onDownload}/>}
                pageSize={25}
                columns={columns}
                rows={filteredRows ?? []}
                defaultSort={{
                    accessor: "partnerName",
                    direction: "asc"
                }}
                idAccessor={row => row.id}
                isLoading={isFetching}
                loadingText={"Loading agreements..."}
            />
        </Page>
    );
}

function CreateButton() {
    const navigate = useNavigate();

    return <div className="flex v-tablet:flex-col flex-row gap-4 v-tablet:mt-[34px]">
        <PrimaryButton label="Create Agreement" rightIcon={<Plus/>} onClick={() => navigate(Paths.agreement.create)}/>
    </div>;
}

function AgreementBody(props: { agreement: Agreement, onGetAttachment: (fileName: string, fileId: string) => void }) {
    const [open, setOpen] = useState(false);

    const agreement = props.agreement;

    const attachments = () => {
        return (<div className="border text-S min-w-[150px]">
            {agreement.fileAttachments?.map(a => <div key={a.fileId}
                                                      className="pl-2 pr-4 py-2 flex gap-2 underline truncate select-none hover:cursor-pointer hover:bg-gray-200 active:bg-gray-400"
                                                      onClick={() => {
                                                          setOpen(false);
                                                          props.onGetAttachment(a.fileName, a.fileId)
                                                      }}>
                <span className="aspect-square w-[16px]"><Download/></span>
                <p className="truncate">{a.fileName}</p>
            </div>)}
        </div>);
    }

    var volumeSteps = [...((agreement?.volumeFeePlans != undefined && agreement.volumeFeePlans != null && agreement.volumeFeePlans.length > 0) ? (agreement.volumeFeePlans![0].feeSteps ?? []) : [])].sort((a, b) => a.fromVolume - b.fromVolume);
    var discountVolumeSteps = [...(agreement?.discountFeePlan != undefined && agreement.discountFeePlan != null ? (agreement.discountFeePlan.feeSteps ?? []) : [])].sort((a, b) => a.fromVolume - b.fromVolume);

    var hasPTDiscount = agreement.hasProductTracker && agreement.discountFeePlan != undefined && agreement.discountFeePlan != null;

    return (
        <div className="py-4 w-full flex flex-row">
            <div className="grid grid-cols-2 w-full gap-y-4 content-start">
                <BodyItem label="Start Date" value={agreement.startDate}/>
                <BodyItem label="End Date" value={agreement.endDate}/>
                {hasPTDiscount && <BodyItem label="Discount Start Date" value={agreement.startDate}/>}
                {hasPTDiscount && <BodyItem label="Discount End Date"
                                            value={format(addDays(parse(agreement.volumeFeePlans![0].fromDate, 'yyyy-MM-dd', new Date()), -1), 'yyyy-MM-dd')}/>}
                <BodyItem label="Created by" value={agreement.createdByUser}/>

                <div className="flex flex-col gap-1 v-tablet:col-span-1">
                    <p className="text-sc text-gray-600 uppercase">{"Brand"}</p>
                    <div className="flex flex-wrap items-center gap-2">
                        {agreement.brands.map(brand => <SimpleBadge className="h-6">{brand}</SimpleBadge>)}
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-y-4 w-full">
                {agreement.hasProductTracker &&
                    <ProductTrackerFeePlan volumeLabel="Wholesale Volume" feeLabel="Wholesale Fee %"
                                           volumeSteps={volumeSteps} currency={agreement.partnerCurrency}/>}
                {hasPTDiscount &&
                    <ProductTrackerFeePlan volumeLabel="Discount Wholesale Volume" feeLabel="Discount Wholesale Fee %"
                                           volumeSteps={discountVolumeSteps} currency={agreement.partnerCurrency}/>}
                {agreement.hasProductTracker && agreement.hasAutoPayment && <div className="border"></div>}
                {agreement.hasAutoPayment && <AutoPaymentSection feePercent={agreement.autoPaymentFeePercent!}/>}
                {agreement.fileAttachments?.length > 0 &&
                    <div className="w-fit ml-auto">
                        <CustomSelectElement xAlign="right" open={open} setOpen={setOpen} body={attachments()}>
                            <div
                                className={`border border-black rounded-full transition-all px-4 py-2 text-S flex gap-2 ${open ? 'bg-gray-200' : ''}`}>
                                <Folder/><p className="mt-[1px]">Attachments <span
                                className="font-semibold">({agreement.fileAttachments.length})</span></p></div>
                        </CustomSelectElement>
                    </div>}
            </div>
        </div>
    );
}

function AutoPaymentSection({feePercent}: { feePercent: number }) {
    return (
        <div>
            <BodyItem label="Auto Payment Fee %" value={feePercent.toFixed(2) + "%"}/>
        </div>
    )
}

function BodyItem(props: { label: string, value: string }) {
    return (
        <div className="flex flex-col gap-1 v-tablet:col-span-1">
            <p className="text-sc text-gray-600 uppercase">{props.label}</p>
            <p className="text-M text-gray-900 font-semibold">{props.value}</p>
        </div>
    );
}

function GetColumns(): TableReportColumn<Agreement>[] {
    return [
        {
            title: "Partner Name",
            accessor: "partnerName",
            sortable: true,
            visibility: "visible"
        },
        {
            title: "Start Date",
            accessor: "startDate",
            sortable: true,
            visibility: "visible"
        },
        {
            title: "End Date",
            accessor: "endDate",
            sortable: true,
            visibility: "visible"
        },
        {
            title: "Auto Payment",
            accessor: "hasAutoPayment",
            sortable: true,
            visibility: "visible",
            render(agreement) {
                return agreement.hasAutoPayment ? "Yes" : "No";
            }
        },
        {
            title: "Product Tracker",
            accessor: "hasProductTracker",
            sortable: true,
            visibility: "visible",
            render(agreement) {
                return agreement.hasProductTracker ? "Yes" : "No";
            }
        },
        {
            title: "Status",
            accessor: "status",
            sortable: true,
            visibility: "visible"
        }
    ]
}

