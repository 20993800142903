import {TrackedWholesaleSearchResult} from "src/pages/TrackedWholesaleReport";
import {organisationApi} from "./OrganisationApi";
import {SupplierInvoiceWholesale, TrackedWholesale} from "./models/ProductTracker";

export const payoutApi = organisationApi.injectEndpoints({
    endpoints: builder => ({
        getTrackedWholesale: builder.query<TrackedWholesale, { startDate: string, endDate: string, filterQuery: string }>({
            query: params => `/api/productTracker/GetTrackedWholesale?startDate=${params.startDate}&endDate=${params.endDate}&${params.filterQuery}`
        }),
        getTrackedWholesaleSearchResult: builder.query<TrackedWholesaleSearchResult, { searchQuery: string }>({
            query: params => `/report/search/GetTrackedWholesaleSearchResult?searchQuery=${params.searchQuery}`
        }),
        getMonthlyTrackedWholesale: builder.query<SupplierInvoiceWholesale[], { startDate: string, endDate: string }>({
            query: params => `/api/productTracker/GetMonthlyTrackedPayment?startDate=${params.startDate}&endDate=${params.endDate}`
        }),
        updateProductTrackerPaymentStatus: builder.mutation<ProductTrackerEndpointResponse, {query: {paymentId: string}, body: Input}>({
            query: ({query, body}) => ({
                url: `/api/productTracker/UpdateTrackedPayment?paymentId=${query.paymentId}`,
                method: 'POST',
                body
            })
        })
    })
});

interface Input{
    Body: string
}
export interface ProductTrackerEndpointResponse {
    content?: any,
    status: 'FAILED' | 'SUCCESS',
    error: string
}

export const {
    useGetTrackedWholesaleQuery,
    useGetTrackedWholesaleSearchResultQuery,
    useGetMonthlyTrackedWholesaleQuery,
    useUpdateProductTrackerPaymentStatusMutation
} = payoutApi;