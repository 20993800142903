import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

export type RtkError = FetchBaseQueryError | SerializedError;

function isSerializedError(error: RtkError): error is SerializedError {
    return (error as SerializedError).message !== undefined;
}

export function getErrorMessage(error: RtkError | undefined) {
    if (!error) {
        return undefined;
    }

    if (isSerializedError(error)) {
        return error.message;
    }

    if (typeof error.data === "string") {
        return error.data;
    }

    if ('status' in error && typeof error.status === "number") {
        if (!error.data || typeof error.data !== "object") {
            return `There was an error. Code: ${error.status}`;
        } else if ("error" in error.data && typeof error.data.error === "string") {
            return error.data.error;
        } else {
            return `There was an error. Code: ${error.status}`;
        }
    }

    if ('error' in error) {
        return error.error;
    }

    return "There was an error.";
}