import { Page } from "@impulso/common/components/Page";
import { Accordion, LoadingOverlay, Pagination, Skeleton } from "@mantine/core";
import { useMemo, useRef, useState } from "react";
import { AdminPayoutResponse } from "src/modules/payouts/api/payout-api";
import { useGetAdminPayoutsQuery } from "src/api/PayoutApi";
import { PrimaryButton } from "@impulso/common/components/buttons/PrimaryButton";
import { paginationStyling } from "@impulso/common/styling/PaginationStyling";
import { useGlobalSecurity } from "src/common/UseGlobalSecurity";
import FormatDate from "@impulso/common/styling/FormatDate"
import Download from "@impulso/common/Icons/Download"
import DownChevronIcon from "@impulso/common/Icons/DownChevronIcon"
import { AsyncLinkDownload } from "@impulso/common/components/link";
import { Language } from "@impulso/common/Language";
import { exportPdf } from "@impulso/common/common/pdfExport";
import { CalenderButton } from "@impulso/common/components/calender/CalenderButton"
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import { useLocation } from "react-router-dom";
import format from "date-fns/format";
import parse from "date-fns/parse";
import { colors } from "@impulso/common/Theme";
import subDays from "date-fns/subDays";


export default function PayoutReport() {

  const { accessToken } = useGlobalSecurity();

  const location = useLocation();
  const locDate = location.state as Date | null;

  const last30Days : [Date, Date] = [subDays(new Date(), 30), new Date()];
  const [dateSpan, setDateSpan] = useState<[Date, Date]>([
    locDate ? startOfWeek(locDate) : last30Days[0], 
    locDate ? endOfWeek(locDate) : last30Days[1]
  ]);

  const startDate = format(dateSpan[0], "yyyy-MM-dd");
  const endDate = format(dateSpan[1], "yyyy-MM-dd");
    
  const {data: rawPayouts, isFetching : isLoading} = useGetAdminPayoutsQuery({startDate, endDate});

  const payouts = useMemo(()=> rawPayouts ?? [], [rawPayouts]);

  const [page, setPage] = useState(1);
  const pageSize = 25;

  const noOfPages = Math.ceil((payouts.length ?? 0) / pageSize);

  const pagedPayouts = useMemo(() => {
    const start = (page - 1) * pageSize;
    const end = start + pageSize;
    return payouts.slice(start, end);
  }, [payouts, page, pageSize]);
  const start = (page - 1) * pageSize;
  const end = Math.min(start + pageSize, payouts.length);

  
  const [buttonLabel, setButtonLabel] = useState<string>("Summarise");
  
  function SummariesPayout ( payouts : AdminPayoutResponse[] ) : AdminPayoutResponse[] {
    if (payouts.length === 0) return [];
    if (payouts.length === 1) return payouts;
    return [{
      payoutDate: `${payouts[payouts.length - 1].payoutDate} -  ${payouts[0].payoutDate}`, 
      orgName: payouts[0].orgName,
      vatId: payouts[0].vatId,
      impulsoFee: payouts.reduce((acc, b) => acc + b.impulsoFee, 0), 
      payout: payouts.reduce((acc, b) => acc + b.payout, 0), 
      payoutExVat: payouts.reduce((acc, b) => acc + b.payoutExVat, 0), 
      payoutVat: payouts.reduce((acc, b) => acc + b.payoutVat, 0),  
      pingFee: payouts.reduce((acc, b) => acc + b.pingFee, 0),
      totalSales: payouts.reduce((acc, b) => acc + b.totalSales, 0),   
      totalSalesExVat: payouts.reduce((acc, b) => acc + b.totalSalesExVat, 0),   
      totalSalesVat: payouts.reduce((acc, b) => acc + b.totalSalesVat, 0),   
      totalInvoices: payouts.reduce((acc, b) => acc + b.totalInvoices, 0),  
      totalInvoicesExVat: payouts.reduce((acc, b) => acc + b.totalInvoicesExVat, 0),  
      totalInvoicesVat: payouts.reduce((acc, b) => acc + b.totalInvoicesVat, 0),  
      totalWholesale: payouts.reduce((acc, b) => acc + b.totalWholesale, 0),  
      totalWholesaleExVat: payouts.reduce((acc, b) => acc + b.totalWholesaleExVat, 0),  
      totalWholesaleVat: payouts.reduce((acc, b) => acc + b.totalWholesaleVat, 0),  
    }]
  }

  const summariesedPayout = SummariesPayout(payouts) ;

  const changeStyle = ( ) => {

    setButtonLabel ( (prev) =>{ return prev == "Summarise" ? "Breakdown" : "Summarise"; })
    
  }

  return (
      <Page hasAccess={true} titleKey="payout.title" rightAction={<PayoutHeader dateSpan={dateSpan} setDateSpan={setDateSpan} label = {buttonLabel}  onClick = {changeStyle}/>}>
        <LoadingOverlay visible={isLoading} />
        <Accordion multiple = {true} chevron={<DownChevronIcon></DownChevronIcon>} styles={{
            item: {
                backgroundColor: "white",
                borderColor: colors.gray[300],
                borderRadius: "0px"
            }
        }} variant="separated">
          
          {buttonLabel === "Summarise" && pagedPayouts.map(payout => 
            <PayoutItem
              id={payout.payoutDate}
              key={payout.payoutDate}
              payout={payout}
              accessToken={accessToken ?? ""}
              loading={isLoading}
            />
          )}
          
          {buttonLabel === "Breakdown" && summariesedPayout.map(payout=> 
          <PayoutItem id = "summaries" key = "summaries" payout={payout} accessToken={accessToken ?? ""} loading={isLoading} isSummary = {true} />)}
        
        </Accordion>
        {buttonLabel === "Summarise" && <div className="relative">
          <Pagination total={noOfPages} value={page} className="my-4" position="center" styles={paginationStyling} onChange={setPage} />
          <div className="absolute right-0 top-0 text-semibold text-gray-700 v-tablet:left-[50%] v-tablet:translate-x-[-50%] v-tablet:text-center v-tablet:top-12 v-tablet:pb-8 "><div>Showing <b>{start + 1} - {end}</b> of <b>{payouts.length}</b></div></div>
        </div>}
      </Page>
     );
}

type PayoutItemProps = {
  payout: AdminPayoutResponse,
  accessToken: string,
  loading: boolean,
  id: string,
  isSummary? : boolean
}

function PayoutItem(props: PayoutItemProps) {
  const reportElement = useRef<HTMLDivElement | null>(null);

  const payoutKey = props.payout.payoutDate;

  const startDate = props.isSummary === undefined ? parse(payoutKey, "yyyy-MM-dd", new Date()) : parse(payoutKey.substring(0,10),  "yyyy-MM-dd", new Date());
  const endDate = props.isSummary === undefined ? parse(payoutKey, "yyyy-MM-dd", new Date()) : parse(payoutKey.length > 13 ? payoutKey.substring(13).trim() : payoutKey,  "yyyy-MM-dd", new Date());

  if (!props.accessToken)
  {
      return null;
  }

  const doExportPdf = (payout: AdminPayoutResponse, element: HTMLDivElement | HTMLElement | null) =>
  {
    exportPdf(
      element,
      "payout-report_" + payout.payoutDate.toString(),
      payout.payoutDate,
      "payout",
      "portrait"
    );
  }

  const headerClasses = 'text-gray-600 font-[400] text-sc uppercase';
  const textHeaderClasses = 'text-black font-[400] text-M';

  return (
    <Accordion.Item className={"hover:bg-gray-100 mt-4"} value={props.id} ref={reportElement}>
      <Accordion.Control className={`relative v-tablet:h-[154px] h-[96px]`}>
        <div className="inset-4 text-xs grid grid-cols-2 justify-between">
          <div> 
            <p className={headerClasses}>payout Auto Payment</p>
            <div className="text-black font-[400] flex flex-row gap-2 items-baseline">
              <p className="text-2xl font-[400] pt-[2px] font-serif">{Language.FormatMoney(props.payout.payout)}</p>
              <p className={textHeaderClasses}>SEK</p>
            </div>
          </div>
          <div className="flex justify-between">
            <div>
              <p className={headerClasses}>Company Name</p>
              <div className="text-black font-[400] flex flex-row gap-2 items-baseline">
                <p className={textHeaderClasses + " pt-2"}>{props.payout.orgName}</p>
              </div>
            </div>
            <div>
              <p className={headerClasses}>VAT ID</p>
              <div className="text-black font-[400] flex flex-row gap-2 items-baseline">
                <p className={textHeaderClasses + " pt-2"}>{props.payout.vatId}</p>
              </div>
            </div>
            <div>
              <p className={headerClasses}>payout date</p>
              <div className="text-black font-[400] flex flex-row gap-2 items-baseline">
                <p className={textHeaderClasses + " pt-2"}>{props.isSummary === undefined ? FormatDate(props.payout.payoutDate): FormatDate(startDate.toDateString()) + ' - ' + FormatDate(endDate.toDateString())}</p>
              </div>
            </div>
          </div>
        </div>
      </Accordion.Control>
      <Accordion.Panel>
          <div className="grid grid-cols-4 pt-4 border-t">
            <div className="flex flex-col gap-4">
                <OverviewItem title="Impulso Fee Ex VAT" value={Language.FormatMoney(props.payout.payout - props.payout.payoutVat, "SEK")} loading={props.loading}></OverviewItem>
                <OverviewItem title="Impulso Fee (Free From VAT)" value={Language.FormatMoney(props.payout.pingFee, "SEK")} loading={props.loading}></OverviewItem>
                <OverviewItem title="Impulso Fee VAT" value={Language.FormatMoney(props.payout.payoutVat, "SEK")} loading={props.loading}></OverviewItem>
                <OverviewItem title="Financial Cost(Free From VAT)" value={Language.FormatMoney(props.payout.pingFee, "SEK")} loading={props.loading}></OverviewItem> 
              </div>
              <div className="flex flex-col gap-4">
                <OverviewItem title="Total Sales" value={Language.FormatMoney(props.payout.totalSales, "SEK")} loading={props.loading}></OverviewItem>
                <OverviewItem title="Total Sales Ex VAT" value={Language.FormatMoney(props.payout.totalSalesExVat, "SEK")} loading={props.loading}></OverviewItem>
                <OverviewItem title="Total Sales VAT" value={Language.FormatMoney(props.payout.totalSalesVat, "SEK")} loading={props.loading}></OverviewItem>
              </div>
              <div className="flex flex-col gap-4">
                <OverviewItem title="Invoice" value={Language.FormatMoney(props.payout.totalInvoices, "SEK")} loading={props.loading}></OverviewItem>
                <OverviewItem title="Invoice Ex VAT" value={Language.FormatMoney(props.payout.totalInvoicesExVat, "SEK")} loading={props.loading}></OverviewItem>
                <OverviewItem title="Invoice VAT" value={Language.FormatMoney(props.payout.totalInvoicesVat, "SEK")} loading={props.loading}></OverviewItem>
              </div>
              <div className="flex flex-col gap-4">
                <OverviewItem title="Wholesale" value={Language.FormatMoney(props.payout.totalWholesale, "SEK")} loading={props.loading}></OverviewItem>
                <OverviewItem title="Wholesale Ex VAT" value={Language.FormatMoney(props.payout.totalWholesaleExVat, "SEK")} loading={props.loading}></OverviewItem>
                <OverviewItem title="Wholesale VAT" value={Language.FormatMoney(props.payout.totalWholesaleVat, "SEK")} loading={props.loading}></OverviewItem>
              </div>
          </div>
          <div className="flex justify-end pt-4 pdf-export-exclude">
            { <div className="pl-4">
              <AsyncLinkDownload label="Export (.pdf)" icon={<Download />} onClick={async () => doExportPdf(props.payout, reportElement.current)}/>
            </div> }
          </div>
      </Accordion.Panel>
    </Accordion.Item>
  );
}

function OverviewItem(props: {title: string, value: string, loading?: boolean}) {
  return (
      <div className="flex flex-col">
      <p className="font-sans font-normal text-sc text-gray-600">{props.title.toUpperCase()}</p>    
      <Skeleton visible={props.loading} radius={0}>
          <p className="pt-2 text-M font-[600]">{props.value}</p>
      </Skeleton>
      </div>
  );
}

function PayoutHeader(props: {setDateSpan: (value: [Date, Date]) => void, dateSpan: [Date, Date], label : string, onClick : () => void  }) {

return <div className="flex flex-col gap-2 v-tablet:flex-col">
      <div><CalenderButton dateSpan={props.dateSpan} setDateSpan={props.setDateSpan} boxSize="v-tablet:w-full w-[240px] h-[32px]"/></div>
      <div><PrimaryButton label = {props.label} onClick={props.onClick}></PrimaryButton></div>
  </div>
}