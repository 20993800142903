import {Indicator, useMantineTheme} from "@mantine/core";
import {DatePickerInput, DatePickerInputProps} from "@mantine/dates";
import addDays from "date-fns/addDays";
import isSameDay from "date-fns/isSameDay";
import {colors} from "@impulso/common/Theme";
import React from "react";

const selectStyle = {
    input: {
        '&:hover': {borderColor: colors.gray[900]},
        '&:focus': {borderColor: colors.brand[600]},
        '&:focus-within': {borderColor: colors.brand[600]},
        '&[aria-expanded=true]': {borderColor: colors.brand[600]},
        borderRadius: 0
    },
    calendar: {
        dropdown: {
            borderRadius: 0
        },
        item: {borderRadius: 0},
        value: {borderRadius: 0}
    }
}

type DateInputPickerProps = {
    today: Date,
    paymentDays?: number | undefined,
    label?: string,
    size?: string,
    required?: boolean,
    placeholder: string,
    disabled?: boolean
} & Pick<DatePickerInputProps, | "onError" | "onBlur" | "onChange" | "onFocus" | "value">;

export function DateInputField({label, size, placeholder, today, paymentDays, ...props}: DateInputPickerProps) {
    const theme = useMantineTheme();
    
    const hastTodayStyle = (day: Date): boolean => {
        if (props.value && isSameDay(day, props.value)) {
            return false;
        }
        
        return isSameDay(day, today);
    }

    return (<DatePickerInput
            disabled={props.disabled}
            className="py-2"
            size={size ?? "xs"}
            label={label ? label : undefined}
            placeholder={placeholder ?? undefined}
            required={props.required ?? true}
            styles={selectStyle}
            clearable={false}
            renderDay={(date) => (
                <Indicator size={6} color={theme.colors.brand[2]} offset={8}
                           disabled={!isCalendarPaymentDate(today, paymentDays!, date)}>
                    <div>{date.getDate()}</div>
                </Indicator>
            )}
            
            getDayProps ={(day) => ({
                style: hastTodayStyle(day) ? {backgroundColor: theme.colors.brand[1]} : {} 
            }) }
            {...props}
        />
    )
}

function calcDueDate(today: Date, paymentDays: number): Date {
    const tomorrow = addDays(today, 1);
    return addDays(tomorrow, paymentDays);
}

function isCalendarPaymentDate(today: Date, paymentDays: number, date: Date) {
    if (date <= today) {
        return false;
    }
    return date < calcDueDate(today, paymentDays);
}