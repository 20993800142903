import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loader, Menu, MultiSelect, Pagination, UnstyledButton } from "@mantine/core";
import { useState, useEffect, useMemo } from "react";
import { colors } from "@impulso/common/Theme";
import { paginationStyling } from "@impulso/common/styling/PaginationStyling";
import { dropdownStyling } from "@impulso/common/styling/DropdownStyling";
import { ExportButton } from "@impulso/common/components/buttons/exportButton";
import DataTable, { DataTableSortStatus, FoldoutDataTable, TableReportColumn } from "./DataTable";
import Gear from "@impulso/common/Icons/Gear";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import React from "react";

export interface TableReportExport {
    onExport: () => void;
    isExporting: boolean;
    label: string;
}

export interface TableReportProps<T>{
    pageSize: number;
    footerRow?: T; //Special row that is always inserted at the end. Very handy if you have a total row or something that shouldn't be affected by sort or paging
    columns: TableReportColumn<T>[];
    rows: T[];
    ignoreBodyVisibility?: boolean;
    defaultSort: { accessor: keyof T & string, direction: "asc" | "desc" };
    idAccessor: (row: T)=>string;
    isLoading: boolean;
    errorMessage?: string;
    rightIcon?: JSX.Element;
    onClick?: (item: T) => void;
    loadingText: string;
    rowHeight?: string;
    noContentMessage?: string;
    exportLink?: TableReportExport;
    rowBody?: (item: T) => JSX.Element;
};

export function TableReport<T>(props: TableReportProps<T>) {
    const [sortStatus, setSortStatus] = useState<DataTableSortStatus>( { columnAccessor: props.defaultSort.accessor, direction: props.defaultSort.direction });
    const [page, setPage] = useState(1);
    const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
    const pageSize = props.pageSize;

    const columns: TableReportColumn<T>[] = useMemo(()=> {
        let availableColumns: ColumnItem[] = props.columns.map(c => ({ value: c.accessor, label: c.title as string, static: c.visibility === "alwaysVisible"}));
        let column = props.columns.filter(c => selectedColumns.includes(c.accessor));
        if(!props.rowBody) {
            const actionColumn: TableReportColumn<T> = {accessor: "_" as any, textAlignment: "right", sortable: false, title: <ColumnSelector availableColumns={availableColumns} selectedColumns={selectedColumns} setSelectedColumns={setSelectedColumns} />};
            column.push(actionColumn);
        }
        return column;
    }, [props.columns, selectedColumns]);
    
    useEffect(() => {
        setSelectedColumns(props.columns.filter(c => c.visibility === "visible" || c.visibility === "alwaysVisible").map(c => c.accessor));
    }, [props.columns]);

    let noOfPages = Math.ceil((props.rows.length ?? 0) / pageSize);
    
    const rows = useMemo(() => {
        if (!props.rows) {
            return undefined;
        }

        const accessor = sortStatus.columnAccessor;
        const dir = sortStatus.direction === "asc" ? 1 : -1;

        const sorted = props.rows.slice().sort((a: any, b: any) => {
            const aVal = a[accessor];
            const bVal = b[accessor];

            if (typeof aVal === "string" && typeof bVal === "string") {
                return aVal.localeCompare(bVal) * dir;
            } else if (typeof aVal === "number" && typeof bVal === "number") {
                return Math.sign(aVal - bVal) * dir;
            } else if (aVal instanceof Date && bVal instanceof Date) {
                return (aVal.getTime() - bVal.getTime()) * dir;
            } else {
                return (aVal ?? "").toString().localeCompare((bVal ?? "").toString()) * dir;
            }
        });

        noOfPages = Math.ceil((props.rows.length ?? 0) / pageSize);
        if(page > noOfPages) {
            setPage(Math.max(noOfPages, 1));
        }

        const start = (page - 1)*pageSize;
        const end = start + pageSize;
        const paged = sorted.slice(start, end).map((r: any) => ({...r, id: props.idAccessor(r)}));
        if (props.footerRow !== undefined) {
            paged.push(props.footerRow);
        }
        return paged;
    }, [props.rows, props.footerRow, props.idAccessor, page, sortStatus.direction, sortStatus.columnAccessor, pageSize]);

    if (props.isLoading) {
        return <div className="mt-16">
            <Loader className="mx-auto "/>
            <p className="text-center mt-6 ml-2">{props.loadingText}</p>
        </div>
    }

    if (props.errorMessage) {
        return <div className="w-full h-[50vh] justify-center items-center flex flex-col">
            <FontAwesomeIcon icon={solid("exclamation-triangle")} size="4x" color={colors.brand.DEFAULT} />
            <p className="mt-4">{props.errorMessage}</p>
        </div>;
    }
    
    const start = Math.max((page - 1)*pageSize, 0);
    const end = Math.min(start + pageSize, props.rows.length);

    return <div className="pb-4 v-tablet:pb-16">
            { props.rowBody ?
            <FoldoutDataTable
                onClick={props.onClick}
                rightIcon={props.rightIcon}
                records={rows} 
                columns={columns} 
                ignoreBodyVisibility={props.ignoreBodyVisibility}
                rowHeight={props.rowHeight} 
                sortStatus={sortStatus} 
                onSortStatusChange={setSortStatus} 
                noContentMessage={props.noContentMessage}
                isLoading={props.isLoading}
                rowBody={props.rowBody}
            /> :
            <DataTable
                records={rows} 
                columns={columns} 
                rowHeight={props.rowHeight} 
                sortStatus={sortStatus} 
                onSortStatusChange={setSortStatus} 
                noContentMessage={props.noContentMessage}
            />}
            <div className="relative">
                <Pagination total={noOfPages} value={page} className="my-4" position="center" styles={paginationStyling} onChange={setPage} />
                {props.exportLink && <ExportButton 
                    label={props.exportLink.label}
                    rightIcon={<></>} 
                    margin="!absolute left-0 top-0 v-tablet:top-12"
                    textSize="text-md" 
                    onClick={props.exportLink.onExport} 
                    loading={props.isLoading || props.exportLink.isExporting} 
                />}
                <p className="absolute right-0 top-0 text-semibold text-gray-700 v-tablet:top-12">Showing <b>{start + 1} - {end}</b> of <b>{props.rows.length}</b></p>
            </div>
    </div>
}

interface ColumnItem {
    label: string;
    value: string;
    static: boolean;
}

function ColumnSelector(props: {availableColumns: ColumnItem[], selectedColumns: string[], setSelectedColumns: (v: string[]) => void}) {
    return (
    <Menu width={330} position="bottom">
        <Menu.Target><UnstyledButton className="align-middle"><Gear/></UnstyledButton></Menu.Target>
        <Menu.Dropdown style={{borderRadius: 0, padding: "8px"}}>
            <MultiSelect styles={dropdownStyling} data={props.availableColumns} value={props.selectedColumns} onChange={e => {
                let requiredColumns = props.availableColumns.filter(c => c.static);
                if (requiredColumns.every(r => e.some(id => r.value === id)))
                {
                    props.setSelectedColumns(e);
                }
            }} className="text-left" label="Add or remove columns" />
        </Menu.Dropdown>
    </Menu>
    )
}