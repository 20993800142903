import ArrowDown from "@impulso/common/Icons/ArrowDown";
import CheckMark from "@impulso/common/Icons/CheckMark";
import LinkIcon from "@impulso/common/Icons/LinkIcon";
import Plus from "@impulso/common/Icons/Plus";
import XMark from "@impulso/common/Icons/XMark";
import { Group } from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import { filetypeAccept } from "./CreateAgreement";

export function AttachmentSection(props: { attachments: File[]; setAttachments: (files: File[]) => void; }) {
	return (<div>
		<p className="flex"><span className="flex text-M h-fit mb-1 gap-2 items-center"><LinkIcon />Attachments <span
			className="text-gray-600 text-S">{Object.values(filetypeAccept).flatMap(fta => fta).sort().join(', ')}</span></span>
		</p>
		<div className="flex flex-col gap-2">
			<Dropzone
				accept={filetypeAccept}
				maxSize={5 * 1024 ** 2}
				onDrop={(files) => {
					props.setAttachments([...props.attachments, ...files]);
				}}>
				<Group>
					<Dropzone.Reject>
						<div className="text-error"><XMark /></div>
					</Dropzone.Reject>
					<Dropzone.Accept>
						<div className="text-confirmation"><CheckMark /></div>
					</Dropzone.Accept>
					<Dropzone.Idle><Plus /></Dropzone.Idle>
					<div className="flex gap-4">
						<p className="text-M">Drag documents here or click to choose files <span
							className="text-gray-600">(max: 5MB)</span></p>
					</div>
				</Group>
			</Dropzone>
			{props.attachments.length > 0 && <>
				<div className="h-[16px] aspect-square text-gray-600 justify-center flex"><ArrowDown /></div>
				<div className="border text-black">
					{props.attachments.map((a, index) => <p className="px-4 py-2 flex gap-4 items-center even:bg-gray-100" key={'attachment-' + index}>
						<span className="text-gray-600 hover:text-error hover:cursor-pointer scale-75 active:text-black" title={'Remove attachment'} data-toggle="tooltip" onClick={() => props.setAttachments([...props.attachments.filter(ac => ac !== a)])}><XMark /></span>
						<span className="text-S">{a.name}</span>
					</p>
					)}
				</div></>}
		</div>

	</div>);
}
