import {useAuth0} from "@auth0/auth0-react";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import { AppDispatch, RootState } from "../Store";
import { setToken } from "../slices/UserSlice";
/**
 * Should only be used in root of Auth in order to trigger login to Auth0 and intercom
 */
export function UseAuth() {
    const {
        loginWithRedirect,
        isAuthenticated,
        isLoading: auth0Loading,
        getAccessTokenSilently
    } = useAuth0();

    const accessToken = useSelector((s: RootState) => s.user.token);

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        if (!auth0Loading && !isAuthenticated) {
            loginWithRedirect().catch(e => console.error(e));
        } else if (!auth0Loading && isAuthenticated) {
            getAccessTokenSilently()
                .then(token => {
                    if (accessToken !== token) {
                        dispatch(setToken(token))
                    }
                })
                .catch(e => console.error(e))
        }
    }, [loginWithRedirect, auth0Loading, isAuthenticated, getAccessTokenSilently, accessToken, dispatch]);


    const isLoading = auth0Loading;

    return {isLoading, isAuthenticated};
}