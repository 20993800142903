import React from "react";

// Packages imports
import {createRoot} from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import {MantineProvider, ColorSchemeProvider} from "@mantine/core";
import {Provider} from "react-redux";

// Components imports
import {App} from "./App";

// Backbone imports
import {GlobalAppsettings} from "./common/GlobalSettings";

// Styles imports
import "./styles/preflight.css";
import "./styles/index.css";
import "./styles/fonts.css";

import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/eb-garamond/400.css";
import "@fontsource/eb-garamond/500.css";
import "@fontsource/eb-garamond/600.css";

import {Auth0Provider} from "@auth0/auth0-react";

import {store, persistor} from "./Store"
import {PersistGate} from "redux-persist/integration/react";

import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import * as enLang from "../i18n/en.json";

import {colors} from "@impulso/common/Theme";

const colorScheme = "light";

const rootElement = document.getElementById("root");

i18n
    // .use(HttpApi)
    .use(initReactI18next)
    .init({
        lng: 'en',
        fallbackLng: 'en',
        resources: {
            en: {translation: enLang} // Bundle english translations with app instead of loading them lazily
        },
        debug: GlobalAppsettings.IsDevelopment
    })
    .then(() => console.log("i18next init complete"))
    .catch(e => console.error(e))

if (!rootElement) {
    throw new Error("No root element found");
}

const root = createRoot(rootElement);

root.render(
    <React.StrictMode>

        <Auth0Provider
            domain="impulso-admin.eu.auth0.com"
            clientId="Ucyr0tEL7gFjSf4ahpviaXcqw9k6qjiM"
            redirectUri={window.location.origin}
            audience="https://api.admin.impulso.se"
            scope="read:user"
            cacheLocation="localstorage"
        >
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={() => {}}>
                        <MantineProvider theme={{
                            colorScheme: colorScheme,
                            fontFamily: 'Inter, Kumbh Sans',
                            fontFamilyMonospace: 'Monaco, Courier, monospace',
                            headings: {fontFamily: 'EB Garamond'},
                            black: "#454545",
                            loader: 'bars',
                            colors: {
                                brand: [colors.brand[50], colors.brand[100], colors.brand[200], colors.brand[300], colors.brand[400], colors.brand[500], colors.brand[600], colors.brand[700], colors.brand[800], colors.brand[900]],
                            },
                            primaryColor: 'brand',
                        }}>
                                <BrowserRouter>
                                    <App/>
                                </BrowserRouter>
                        </MantineProvider>
                    </ColorSchemeProvider>
                </PersistGate>
            </Provider>
        </Auth0Provider>
    </React.StrictMode>
);

