type arrowProps ={
    width?: string | '16', 
    height?: string | '16'
} 

export default function ImpulsoSmall(props: arrowProps) {

    return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_1679_20525" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
            <path d="M32 0H0V32H32V0Z" fill="white"/>
        </mask>
        <g mask="url(#mask0_1679_20525)">
            <path d="M32 16C32 19.1645 31.0616 22.2579 29.3035 24.8891C27.5454 27.5203 25.0466 29.5711 22.1229 30.7821C19.1993 31.9931 15.9823 32.3099 12.8786 31.6926C9.77487 31.0752 6.92394 29.5514 4.6863 27.3137C2.44866 25.0761 0.924806 22.2251 0.307443 19.1214C-0.309921 16.0177 0.00693258 12.8007 1.21793 9.87707C2.42894 6.95345 4.4797 4.45459 7.11088 2.69649C9.74207 0.938384 12.8355 0 16 0C20.2435 0 24.3131 1.68571 27.3137 4.68629C30.3143 7.68687 32 11.7565 32 16ZM8.00001 23.8953H11.2V13.5287H8.00001V23.8953ZM20.8 23.8953H24V13.5287H20.8V23.8953ZM14.4 23.8953H17.6V13.5287H14.4V23.8953ZM16 7.92867C15.4696 7.92867 14.9609 8.13938 14.5858 8.51445C14.2107 8.88953 14 9.39823 14 9.92867C14 10.3242 14.1173 10.7109 14.3371 11.0398C14.5568 11.3687 14.8692 11.6251 15.2346 11.7764C15.6001 11.9278 16.0022 11.9674 16.3902 11.8902C16.7782 11.8131 17.1345 11.6226 17.4142 11.3429C17.6939 11.0632 17.8844 10.7068 17.9616 10.3188C18.0387 9.93089 17.9991 9.52875 17.8478 9.1633C17.6964 8.79785 17.44 8.48549 17.1111 8.26573C16.7822 8.04597 16.3956 7.92867 16 7.92867Z" fill="#FF6900"/>
        </g>
    </svg>
    
}
