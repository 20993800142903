import { Language } from "@impulso/common/Language";
import { Page } from "@impulso/common/components/Page";
import { CalenderButton } from "@impulso/common/components/calender/CalenderButton";
import { TableReportColumn } from "@impulso/common/components/report/DataTable";
import { TableReport } from "@impulso/common/components/report/TableReport";
import { IFilterItem, generalFilter } from "@impulso/common/filters/generalFilter";
import { Skeleton } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import subDays from "date-fns/subDays";
import { useCallback, useMemo, useState } from "react";
import { useGetTrackedWholesaleSearchResultQuery } from "src/api/ProductTrackerApi";
import { TrackedWholesaleFilterKey } from "./TrackedWholesaleReport";
import SearchBar from "@impulso/common/components/searchBar/SearchBar";
import { SupplierSales } from "src/api/models/AutoPayment";
import format from "date-fns/format";
import { useGetAutoPaymentSalesQuery } from "src/api/AutoPaymentApi";
import { PrimaryButton } from "@impulso/common/components/buttons/PrimaryButton";
import { useGlobalSecurity } from "src/common/UseGlobalSecurity";
import { downloadAutoPaymentSales, downloadAutoPaymentSalesForSupplier } from "src/api/DownloadApi";
import { AsyncLinkDownload } from "@impulso/common/components/link";
import Download from "@impulso/common/Icons/Download";
import { OrganisationId } from "src/UserProfile";

const defaultFilterData: Record<TrackedWholesaleFilterKey, IFilterItem[]> = {   
  'brands': [],
  'retailers': [],
  'suppliers': []
}

export function AutoPaymentReport() {
  const last30Days : [Date, Date] = [subDays(new Date(), 30), new Date()];
  const [dateSpan, setDateSpan] = useState<[Date, Date]>(last30Days);

  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const { accessToken } = useGlobalSecurity();

  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(generalFilter(defaultFilterData));

  const [debouncedSearch] = useDebouncedValue(search, 500);
  const minSearchLength = 3;
  const {data: searchResults, isFetching} = useGetTrackedWholesaleSearchResultQuery({searchQuery: debouncedSearch }, {skip: (debouncedSearch.length < minSearchLength)});

  const startDate = format(dateSpan[0], "yyyy-MM-dd");
  const endDate = format(dateSpan[1], "yyyy-MM-dd");
  const {data, isFetching : isLoading} = useGetAutoPaymentSalesQuery({startDate, endDate, filterQuery: filter.toQueryString()});
  
  const retailerClick = useCallback(
    (supplierOrgId: OrganisationId, supplierName: string) => {
      return downloadAutoPaymentSalesForSupplier(
        supplierOrgId,
        supplierName,
        startDate,
        endDate,
        filter.toQueryString(),
        accessToken!
      );
    },
    [startDate, endDate, filter, accessToken]
  );

  const columnData = useMemo(() => createColumns(retailerClick), [retailerClick]);

  async function onExportXls() {
    try {
      setIsLoadingExport(true);
      await downloadAutoPaymentSales(startDate, endDate, filter.toQueryString(), accessToken!);
    } finally {
      setIsLoadingExport(false);
    }
  }

  return <Page hasAccess={true} titleKey="autoPaymentReport.report" rightAction={
    <div className="mb-4">
      <div className="mb-4">
        <PayoutHeader dateSpan={dateSpan} setDateSpan={setDateSpan} />
      </div>
      <PrimaryButton label={"Download Breakdown"} disabled={isLoadingExport} onClick={onExportXls}/>
    </div>
  }>
    <SearchBar 
      filter={filter} 
      setFilter={setFilter} 
      searchString={search} 
      setSearch={setSearch} 
      searchResult={searchResults ?? defaultFilterData} 
      loading={isFetching} 
      minSearchLength={minSearchLength}
      placeholder="Search for supplier, retailer or brand"
      id="admin-autopayment-searchbar"
    />
    <Overview 
      isLoading={isLoading}
      totalSalesExVat={data?.totalSalesExVat ?? 0} 
      totalInvoicesExVat={data?.totalInvoicesExVat ?? 0} 
      totalFeesExVat={data?.totalFeeExVat ?? 0}
      totalCustomers={data?.totalCustomers ?? 0} 
    />
    <CurrencyRates
      sek={data?.sekConversionRate ?? 0}
      dkk={data?.dkkConversionRate ?? 0}
      nok={data?.nokConversionRate ?? 0}
      gbp={data?.gbpConversionRate ?? 0}
    />
    <TableReport 
      pageSize={50} 
      rows={data?.suppliers ?? []}
      columns={columnData}
      defaultSort={{
        accessor: "supplierName",
        direction: "asc"
      }}
      isLoading={isLoading} 
      loadingText={"Loading"} 
      idAccessor={(a) => a.supplierOrgId}
    />
  </Page>;
}

interface OverviewProps {
  isLoading: boolean;
  totalSalesExVat: number;
  totalInvoicesExVat: number;
  totalFeesExVat: number;
  totalCustomers: number;
}

export function Overview(props: OverviewProps) {
  return (
    <div className="grid desktop:grid-cols-4 mt-6 grid-cols-2 v-tablet:grid-cols-1 gap-[24px]">
      <OverviewItem title="Total Sales (ex VAT)" amount={Language.FormatMoney(props.totalSalesExVat)} unit="EUR" loading={props.isLoading} />
      <OverviewItem title="Total Invoices (ex VAT)" amount={Language.FormatMoney(props.totalInvoicesExVat)} unit="EUR" loading={props.isLoading} />
      <OverviewItem title="Total Fees (ex VAT)" amount={Language.FormatMoney(props.totalFeesExVat)} unit="EUR" loading={props.isLoading} />
      <OverviewItem title="Customers" amount={props.totalCustomers.toString()} unit="" loading={props.isLoading} />
    </div>
  );
}

function OverviewItem(props: {title: string, amount: string, unit: string, loading?: boolean}) {
  return (
    <div className="flex flex-col border-t border-gray-300 pt-2 gap-[7px] col-span-1 mb-6">
      <p className="font-sans font-normal text-sc text-gray-600">{props.title.toUpperCase()}</p>    
      <Skeleton visible={props.loading} radius={0}>
        <p className="font-serif text-4xl">{props.amount}<span className="ml-2 font-sans text-l">{props.unit}</span></p>
      </Skeleton>
    </div>
  );
}

function PayoutHeader(props: {setDateSpan: (value: [Date, Date]) => void, dateSpan: [Date, Date] }) {

  return <div>
    <CalenderButton dateSpan={props.dateSpan} setDateSpan={props.setDateSpan} boxSize="v-tablet:w-full w-[240px] h-[32px]"/>
  </div>
}

interface CurrencyRatesProps {
  sek: number;
  dkk: number;
  nok: number;
  gbp: number;
}

function CurrencyRates(props: CurrencyRatesProps) {
const style = "font-sans font-normal text-sc text-gray-500";

  return (
    <div className="mb-6 flex grid grid-cols-4 gap-[24px]">
      <p  className={style}>
        {"1 EUR = " + Language.FormatMoney(props.sek) + " SEK"}
      </p>
      <p  className={style}>
        {"1 EUR = " + Language.FormatMoney(props.nok)  + " NOK"}
      </p>
      <p  className={style}>
        {"1 EUR = " + Language.FormatMoney(props.dkk)  + " DKK"}
      </p>
      <p  className={style}>
        {"1 EUR = " + Language.FormatMoney(props.gbp)  + " GBP"}
      </p>
    </div>
  );
}

function createColumns(onRetailerClick: (supplierId: OrganisationId, supplierName: string) => Promise<void>): TableReportColumn<SupplierSales>[] {
  return [
    {
      title: "Supplier Name",
      accessor: "supplierName",
      visibility: "alwaysVisible",
      sortable: true,
    },
    {
      title: "Total Sales",
      accessor: "totalSalesExVat",
      render: s => Language.FormatMoney(s.totalSalesExVat, "EUR"),
      visibility: "visible",
      sortable: true,
    },
    {
      title: "Total Invoice",
      accessor: "totalInvoiceExVat",
      render: s => Language.FormatMoney(s.totalInvoiceExVat, "EUR"),
      visibility: "visible",
      sortable: true,
    },
    {
      title: "Total Fee",
      accessor: "totalFeeExVat",
      render: s => Language.FormatMoney(s.totalFeeExVat, "EUR"),
      visibility: "visible",
      sortable: true,
    },
    {
      title: "Retailers",
      accessor: "retailerCount",
      visibility: "visible",
      render: s => <RetailerLink retailerCount={s.retailerCount} onClick={() => onRetailerClick(s.supplierOrgId, s.supplierName)}/>,
      sortable: true,
    },
  ];
}

interface RetailerLinkProps {
  onClick: () => Promise<void>;
  retailerCount: number;
}
function RetailerLink(props: RetailerLinkProps) {
  return (
    <AsyncLinkDownload
        icon={<Download />}
        onClick={props.onClick}
        label={props.retailerCount + " retailer(s)"}
    />
  );
}
