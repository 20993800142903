import { organisationApi } from "./OrganisationApi";
import { AutoPaymentSales } from "./models/AutoPayment";

export const autoPaymentApi = organisationApi.injectEndpoints({
    endpoints: builder => ({
        getAutoPaymentSales: builder.query<AutoPaymentSales, {startDate: string, endDate: string, filterQuery: string}>({
            query: params => `/api/autoPayment/GetAutoPaymentSales?startDate=${params.startDate}&endDate=${params.endDate}&${params.filterQuery}`
        }),
    })
});

export const {
  useGetAutoPaymentSalesQuery
} = autoPaymentApi;