import {colors} from "@impulso/common/Theme";
import React from "react";

type MainLogoProps = {
  width?: string;
  height?: string
  color?: string
}

export function MainLogo(props: MainLogoProps) {
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={props.width ?? "132"}
          height={props.height ?? "36"}
          viewBox="0 0 132 36"
        >
          <g
            fill={props.color ?? colors.brand.alternative}
            data-name="Group 1"
            transform="translate(-561.866 -761.658)"
          >
            <path
              d="M0 0H5.305V26.047H0z"
              data-name="Rectangle 2"
              transform="translate(648.481 762.082)"
            ></path>
            <path
              d="M0 0H5.305V17.187H0z"
              data-name="Rectangle 3"
              transform="translate(573.024 771.284)"
            ></path>
            <path
              d="M0 0H5.305V17.187H0z"
              data-name="Rectangle 4"
              transform="translate(562.529 771.284)"
            ></path>
            <path
              d="M0 0H5.305V17.187H0z"
              data-name="Rectangle 5"
              transform="translate(583.634 771.284)"
            ></path>
            <path
              d="M0 0H5.305V17.187H0z"
              data-name="Rectangle 6"
              transform="translate(594.243 771.284)"
            ></path>
            <path
              d="M831.079 815.1a8.977 8.977 0 00-5.383 1.783v-1.358h-4.519v26.046h5.3v-9.7a9.018 9.018 0 104.6-16.776zm-.491 12.731a3.713 3.713 0 113.713-3.713 3.713 3.713 0 01-3.713 3.711z"
              data-name="Path 1"
              transform="translate(-216.324 -43.911)"
            ></path>
            <path
              d="M1258.354 815.1a9.018 9.018 0 109.018 9.018 9.018 9.018 0 00-9.018-9.018zm0 12.731a3.713 3.713 0 113.713-3.713 3.713 3.713 0 01-3.713 3.711z"
              data-name="Path 2"
              transform="translate(-573.506 -44.238)"
            ></path>
            <path
              d="M969.123 827.157a2.776 2.776 0 11-5.552 0v-9.5h-5.3v9.5a8.081 8.081 0 1016.162 0v-9.5h-5.3z"
              data-name="Path 3"
              transform="translate(-330.687 -46.366)"
            ></path>
            <path
              d="M565.182 761.658a3.318 3.318 0 102.344.972 3.194 3.194 0 00-2.344-.972z"
              data-name="Path 4"
            ></path>
            <path
              d="M1150.3 821.781l-1.414-.29c-.653-.145-1.306-.29-1.306-1.052 0-1.85 3.264-1.342 5-.544l1.849-3.881a13.892 13.892 0 00-5.4-.907 9.9 9.9 0 00-3.046.544 6.5 6.5 0 00-2.321 1.269c-2.14 1.777-2.647 5.621-.689 7.471a7.642 7.642 0 003.88 1.523c.653.109 2.1.326 2.466.943.4.725-.29 1.741-1.7 1.85a8.685 8.685 0 01-4.751-1.523l-1.995 4.243a13.279 13.279 0 006.745 1.668c3.119-.109 6.057-1.305 7.217-3.88a5.289 5.289 0 00-.834-5.839 7.292 7.292 0 00-3.701-1.595z"
              data-name="Path 5"
              transform="translate(-483.024 -44.238)"
            ></path>
          </g>
        </svg>
      );
}