import {Loader, LoadingOverlay, UnstyledButton} from "@mantine/core";
import React, {FC} from "react";

export const FullscreenLoader: FC<{message: string}> = props => (
  <FullscreenLoaderContainer>
      <Loader />
      <p className="text-center font-light mt-4">{props.message}</p>
  </FullscreenLoaderContainer>
)

export const FullscreenError: FC<{message: string, logout: () => void}> = props => (
  <FullscreenLoaderContainer>
      <p className="text-center font-light mt-4">
          {props.message}<br/>
          <UnstyledButton className="underline" onClick={props.logout}>Logout</UnstyledButton>
      </p>
  </FullscreenLoaderContainer>
)

export const FullscreenLoaderContainer: FC<{children?: React.ReactNode}> = props => (
  <div className="relative h-screen">
      <LoadingOverlay visible={true} loader={
          <div className="flex flex-col content-center items-center">
              {props.children}
          </div>
      } />
  </div>
)