const Paths = {
  productTracker: {
    trackedWholesaleReport: "/product-tracker/tracked-wholesale",
    trackedInvoice: "/product-tracker/tracked-wholesale/tracked-invoice",
  },
  autoPayment: {
    autoPaymentReport: "/auto-payment",
    payoutReport: "/auto-payment/payout-report",
  },
  agreement: {
    agreements: "/agreements",
    create: "/agreements/create"
  }
}

export default Paths;