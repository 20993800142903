import React from 'react';

export default function useMobileDetect() {
    const [lastWidth, setLastWidth] = React.useState(0);
    useCommonBody(setLastWidth, 100);

    return { isMobileSize: (lastWidth < 460), initialized: lastWidth !== 0 };
}

export function useVTabletSizeDetect() {
    const [lastWidth, setLastWidth] = React.useState(0);
    useCommonBody(setLastWidth, 25);

    return { isVTabletSize: (lastWidth < 1078), initialized: lastWidth !== 0 };
}

export function useHTabletSizeDetect() {
    const [lastWidth, setLastWidth] = React.useState(0);
    useCommonBody(setLastWidth, 25);

    return { isHTabletSize: (lastWidth < 1280), initialized: lastWidth !== 0 };
}

function useCommonBody(setLastWidth: (value: number) => void, debounceValue: number) {
    React.useEffect(() => {
        const handleResize = debounce(() => {
          setLastWidth(window.innerWidth);
        }, debounceValue);
        if (document.readyState === "complete") {
            handleResize();
        }
        else {
            window.addEventListener("load", handleResize);
        }
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("load", handleResize);
        };
    }, []);
}

function debounce(fn: Function, ms: number) {
    let timeoutId: ReturnType<typeof setTimeout>;
    return function (this: any, ...args: any[]) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), ms);
    };
}