export default function ProductTracker(){
    return <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22 2V10L2 10V2L22 2Z" stroke="currentcolor" strokeWidth="2" />
        <path d="M2 10V18H22V10H2Z" stroke="currentcolor" strokeWidth="2" />
        <path d="M11.9998 18V22" stroke="currentcolor" strokeWidth="2" />
        <path d="M15.9998 22H7.99982" stroke="currentcolor" strokeWidth="2" />
        <path d="M5.99969 6H8.50001" stroke="currentcolor" strokeWidth="2" />
        <path d="M5.99969 14H8.50001" stroke="currentcolor" strokeWidth="2" />
        <path d="M11 6H17.9999" stroke="currentcolor" strokeWidth="2" />
        <path d="M11 14H17.9994" stroke="currentcolor" strokeWidth="2" />
    </svg>

}
    
