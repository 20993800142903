import { ScrollArea } from "@mantine/core";
import NavigationSidebar from "./common/NavigationSidebar";
import { UseAuth } from "./common/UseAuth";
import { useGlobalSecurity } from "./common/UseGlobalSecurity";
import { FullscreenLoader } from "@impulso/common/components/Loader";
import { TranslatedPageBoundary } from "@impulso/common/components/PageUpdateReload";
import { Route, Routes, useLocation } from "react-router-dom";
import Paths from "./configuration/Paths";
import Dashboard from "./pages/Dashboard";
import PayoutReport from "./pages/PayoutReport";
import { TrackedWholesaleReport } from "./pages/TrackedWholesaleReport";
import { Agreements } from "./pages/agreements/Agreements";
import { CreateAgreement } from "./pages/agreements/CreateAgreement";
import { AutoPaymentReport } from "./pages/AutoPaymentReport";
import TrackedWholesaleInvoice from "./pages/TrackedWholesaleInvoice";

export function App() {
  const {isLoading, isAuthenticated} = UseAuth();
  const {logout, accessToken} = useGlobalSecurity();
  // Used for clearing page error boundary when navigating
  const location = (useLocation()).pathname;

  if (!isAuthenticated) {
    return <div>You are not logged in...</div>;
  }

  if (isLoading || !isAuthenticated || !accessToken) {
    return <FullscreenLoader message="Loading"/>
  }
  
  return <div className="flex h-screen overflow-y-hidden absolute inset-0">
    <NavigationSidebar onLogoutClick={logout}/>
    <ScrollArea className="overflow-y-auto flex-grow pb-3">
      <div>
          <TranslatedPageBoundary key={location}>
            <Routes>
                <Route index element={<Dashboard />} />
                <Route path={Paths.autoPayment.payoutReport} element={<PayoutReport />} />
                <Route path={Paths.autoPayment.autoPaymentReport} element={<AutoPaymentReport />} />
                <Route path={Paths.productTracker.trackedWholesaleReport} element={<TrackedWholesaleReport />} />
                <Route path={Paths.productTracker.trackedInvoice} element={<TrackedWholesaleInvoice />} />
                <Route path={Paths.agreement.agreements} element={<Agreements />} />
                <Route path={Paths.agreement.create} element={<CreateAgreement />} />
            </Routes>
          </TranslatedPageBoundary>
      </div>
    </ScrollArea>
  </div>;
}