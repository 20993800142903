import {Loader} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ReactNode } from "react-markdown/lib/ast-to-react";
import {useState} from "react";
import Download from "@impulso/common/Icons/Download";
import React from "react";

type LinkProps = {
    path: string,
    label: string
}

export function Link(props: LinkProps){
    const navigate = useNavigate();

    return <LinkButton
        label={props.label}
        icon='arrow-right'
        onClick={() => navigate(props.path)}
    />;
}

export type LinkButtonProps = {
    label: string,
    icon?: IconProp | ReactNode,
    onClick: () => void,
    disabled? :boolean,
    loading?: boolean,
    size?: string,
    underlined?: boolean
}

export function LinkDownload(props: LinkButtonProps){
    return <LinkButton {...props} icon={props.icon ?? <Download/>} />;
}

export function LinkButton(props: LinkButtonProps){
    const style = "flex gap-2 bg-transparent items-center justify-center select-none relative whitespace-nowrap flex-nowrap pdf-export-exclude";
    const activeStyle = "cursor-pointer text-gray-900 hover:text-brand active:text-brand-900 active:translate-y-[1px]";
    const disabledStyle = "text-gray-500";
    const disabled = props.disabled || props.loading;
    const underlined = props.underlined == undefined || props.underlined

    return(
        <button
            disabled={disabled}
            type="button"
            onClick={()=>{return props.onClick()}}
            className={`${style} ${disabled ? disabledStyle : activeStyle} ${props.size ?? "text-l"}`}
        >
            <Loader size="sm" className={`${props.loading ? 'visible' : 'invisible'} absolute left-[50%] translate-x-[-50%]`} />
            <div className={`${props.loading ? 'opacity-0' : 'opacity-100'} flex items-center gap-2 whitespace-nowrap`}>
                <p className={underlined ? 'underline' : ''}>{props.label}</p>
                <NavLinkIconInner icon={props.icon}/>
            </div>
        </button>
    );
}


type AsyncLinkDownloadProps = {
    label: string,
    icon: IconProp | ReactNode,
    onClick: () => Promise<void>,
}

/**
 * Similar to {@link LinkDownload}, but keeps its own loading state by tracking Promise returned from callback
 */
export function AsyncLinkDownload(props: AsyncLinkDownloadProps)
{
    const [loading, setLoading] = useState(false);

    const doAsync = () => {
        setLoading(true);
        props.onClick()
            .catch(e => console.error(e))
            .finally(() => setLoading(false));
    }

    return <LinkDownload {...props} loading={loading} onClick={doAsync} />
}

function NavLinkIconInner(props: { icon?: IconProp | ReactNode}) {
    if (!props.icon) {
        return null;
    }

    if (typeof props.icon == "object" && "icon" in props.icon) {
        return <FontAwesomeIcon className="w-4 h-4" icon={props.icon as IconProp}/>
    }

    return <>{props.icon}</>
}