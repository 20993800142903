
// Backbone imports
//import { GlobalAppsettings } from '../GlobalSettings';

export class Language {
    public static FormatMoney(num: number, suffix: string = "", fractions = 2): string {
        if (!Intl?.NumberFormat) {
            return num.toFixed(fractions);
        }

        const formatter = Intl.NumberFormat("sv-SE", {minimumFractionDigits: fractions, maximumFractionDigits: fractions});

        return `${formatter.format(num)}${suffix ? ` ${suffix}` : ''}`;
    }

    public static FormatMoneyOnly(num: number, fractions = 2): string {
        if (!Intl?.NumberFormat) {
            return num.toFixed(fractions);
        }

        const formatter = Intl.NumberFormat("sv-SE", {minimumFractionDigits: fractions, maximumFractionDigits: fractions});

        return `${formatter.format(num)}`;
    }

    public static GetLocale() {
        return 'en-GB'; // GlobalAppsettings.DefaultLang;
    }
}

