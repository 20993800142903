import parse from "date-fns/parse";
import format from "date-fns/format";
import type {ECharts} from "echarts";
import secondsToMilliseconds from "date-fns/secondsToMilliseconds";

type DateRange = [Date, Date]
type DateLike = Date | DateRange | string | undefined;

function formatFileDate(date: DateLike, dateFormat = "yyyy-MM-dd"): string | undefined {
    if (!date) {
        return undefined;
    }
    try {
        if (Array.isArray(date)) {
            return date.map(d => format(d, dateFormat)).join("_");
        }

        const parsed = typeof date === "string" ? parse(date, "dd MMM yyyy", new Date()) : date;

        return format(parsed, dateFormat);
    } catch (e) {
        return undefined;
    }
}

function informationContentToExportFileName(title: string | undefined, date: DateLike): string {
    const name = title?.toLowerCase().replaceAll(" ", "_") ?? "report";
    const formattedDate = formatFileDate(date);
    return formattedDate ? `${formattedDate}_${name}.pdf` : `${name}.pdf`;
}

type ReportType = "chart" | "withdrawal" | "payout" | "vatReport";

export async function exportPdf(
    report: HTMLDivElement | null | HTMLElement,
    title: string | undefined,
    date: DateLike,
    reportType: ReportType,
    orientation: "landscape" | "portrait" = "landscape",
    echarts?: ECharts,
) {
    if (!report)
    {
        return;
    }

    const {jsPDF} = await import(/* webpackChunkName: 'JsPdf', webpackPrefetch: true */ "jspdf");

    const doc = new jsPDF(orientation, "mm", "a4");

    doc.addFont(new URL("/fonts/Inter-Light.ttf", document.baseURI), "Inter", "normal");

    doc.setFont("Inter");

    const width = doc.internal.pageSize.getWidth();

    const pdfMargin = 10;

    let chartCanvasReplacer = echarts ? await echartsCanvasReplacement(echarts) : undefined;

    await doc.html(report, {
        html2canvas: {
            svgRendering: true,
            removeContainer: true,
            onclone: doc => {
                doc.querySelectorAll("svg").forEach(svg => svg.parentElement?.removeChild(svg));
                doc.querySelectorAll<HTMLElement>("h1, h2, p, label").forEach(e => {
                    e.style.transform = "translate(0, -0.5em)";
                });

                doc.querySelectorAll<HTMLElement>(".mantine-ScrollArea-root").forEach(e => {
                    e.removeAttribute("style");
                    e.removeAttribute("class");
                })

                // Prevent text from getting clipped
                doc.querySelectorAll<HTMLElement>(".truncate").forEach(e => {
                    e.style.overflow = "visible";
                });

                doc.querySelectorAll<HTMLElement>(".pdf-export-exclude").forEach(e => {
                    e.className += " !hidden";
                });

                doc.querySelectorAll<HTMLElement>(".pdf-export-only").forEach(e => {
                    e.className += " !block";
                }); 
                chartCanvasReplacer?.onClone(doc);
            }
        },
        callback: function (doc) {
            const filename = informationContentToExportFileName(title, date);
            doc.save(filename);
        },
        width: width - (2 * pdfMargin),
        windowWidth: report.clientWidth,
        x: pdfMargin,
        y: pdfMargin,
        fontFaces: [
            {family: "Inter", style: "normal", weight: 300, src: [{url: "/fonts/Inter-Light.ttf", format: "truetype"}]},
            {family: "Inter", style: "normal", weight: 600, src: [{url: "/fonts/Inter-SemiBold.ttf", format: "truetype"}]},
            {family: "Inter", style: "normal", weight: 700, src: [{url: "/fonts/Inter-Bold.ttf", format: "truetype"}]},
            {family: "EB Garamond", style: "normal", weight: 400, src: [ {url: "/fonts/EBGaramond.ttf", format: "truetype"}]}
        ]
    });

    chartCanvasReplacer?.revokeObjectURL();
}

async function echartsCanvasReplacement(echarts: ECharts) {
    const canvasReplacement = document.createElement("img");
    canvasReplacement.className = "w-full h-full"
    canvasReplacement.src = echarts.getDataURL({type: "png", pixelRatio: 4, backgroundColor: "#FFFFFF"});

    await imageLoadPromise(canvasReplacement);

    return {
        onClone: (doc: XMLDocument) => {
            // Replaces canvas with pre-rendered image

            // For some reason, there are two copies of `.echarts-for-react`, but only this one needs to be replaced
            const container = doc.querySelector(".html2pdf__overlay .echarts-for-react");
            container?.parentNode?.replaceChild(canvasReplacement, container);
        },
        revokeObjectURL: () => {
            window.URL.revokeObjectURL(canvasReplacement.src);
            canvasReplacement.src = "";
        }
    }
}

async function imageLoadPromise(img: HTMLImageElement, timeoutInMs: number = secondsToMilliseconds(15)) {
    return new Promise<void>((resolve, reject) => {
        const timeout = window.setTimeout(() => {
            reject(new Error("timed out"));
        }, timeoutInMs);

        img.onload = () => {
            window.clearTimeout(timeout);
            resolve();
        }

        img.onerror = (e) => {
            window.clearTimeout(timeout);
            reject(e);
        }
    });
}