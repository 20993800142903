/**!
 * @file This file contains functions for downloading files, which should not be cached in browser by RTK Query, but downloaded directly to filesystem.
 */

import { OrganisationId } from "src/UserProfile";
import { GlobalAppsettings } from "src/common/GlobalSettings";

export async function downloadTrackedWholesale(startDate: string, endDate: string, filterQuery: string, accessToken: string) {
    try {
        const url = `/api/productTracker/GetTrackedWholesaleExcel?startDate=${startDate}&endDate=${endDate}&${filterQuery}`;
        const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
        downloadBlob(blob, `tracked-wholesale ${startDate} - ${endDate}.xlsx`);
    } catch (e) {
        console.error("Could not get tracked wholesale", e);
    }
}

export async function downloadTrackedWholesaleForSupplier(supplierOrgId: OrganisationId, supplierName: string, startDate: string, endDate: string, filterQuery: string, accessToken: string) {
    try {
        const url = `/api/productTracker/GetTrackedWholesaleExcelForSupplier?supplierOrgId=${supplierOrgId}&startDate=${startDate}&endDate=${endDate}&${filterQuery}`;
        const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
        downloadBlob(blob, `${supplierName} tracked-wholesale ${startDate} - ${endDate}.xlsx`);
    } catch (e) {
        console.error("Could not get tracked wholesale for supplier", e);
    }
}

export async function downloadAutoPaymentSales(startDate: string, endDate: string, filterQuery: string, accessToken: string) {
    try {
        const url = `/api/autoPayment/GetAutoPaymentSalesExcel?startDate=${startDate}&endDate=${endDate}&${filterQuery}`;
        const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
        downloadBlob(blob, `auto-payment ${startDate} - ${endDate}.xlsx`);
    } catch (e) {
        console.error("Could not get auto payment sales", e);
    }
}

export async function downloadAutoPaymentSalesForSupplier(supplierOrgId: OrganisationId, supplierName: string, startDate: string, endDate: string, filterQuery: string, accessToken: string) {
    try {
        const url = `/api/autoPayment/GetAutoPaymentSalesExcelForSupplier?supplierOrgId=${supplierOrgId}&startDate=${startDate}&endDate=${endDate}&${filterQuery}`;
        const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
        downloadBlob(blob, `${supplierName} auto-payment ${startDate} - ${endDate}.xlsx`);
    } catch (e) {
        console.error("Could not get auto payment sales for supplier", e);
    }
}

export async function downloadProductTrackerPaymentForSupplier(paymentId: string, supplierName: string, invoiceMonth: string, accessToken: string) {
    try {
        const url = `/api/productTracker/GetProductTrackerPaymentExcelForSupplier?paymentId=${paymentId}`;
        const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
        downloadBlob(blob, `${supplierName}_product-tracker_invoice_${invoiceMonth}.xlsx`);
    } catch (e) {
        console.error("Could not get product tracker payments for supplier", e);
    }
}

export async function downloadAgreementAttachment(accessToken: string, fileName: string, fileId: string) {
    try {
        const url = `/api/agreement/GetFileAttachment?fileId=${fileId}`;
        const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
        downloadBlob(blob, fileName);
    } catch (e) {
        console.error("Could not get requested attachment", e);
    }
}

async function baseQuery(url: string, accessToken: string) {
    const response = await fetch(url, {
        credentials: "same-origin",
        headers: {
            'Authorization': `Bearer ${accessToken}`
        },
    });

    if (!response.ok) {
        throw new Error("Failed to get payout by recipient " + await response.text());
    }

    return await response.blob();
}

function downloadBlob(blob: Blob, fileName: string) {
    const url = window.URL.createObjectURL(blob);
    downloadObjectUrl(url, fileName);
    window.URL.revokeObjectURL(url);
}

export function downloadObjectUrl(url: string, fileName: string) {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    // the filename you want
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}
