import { CreateBanner } from "../EndpointBanner";
import ReportFoldout from "../ReportFoldout";
import SearchOrganisationField from "./SearchOrganisation";
import { FormEvent, useEffect, useState } from "react";
import { Checkbox, Divider, Loader, Select, TextInput } from "@mantine/core";
import { dropdownStyling } from "@impulso/common/styling/DropdownStyling";
import { PrimaryButton } from "@impulso/common/components/buttons/PrimaryButton";
import { OrganisationData, useGetOrganisationQuery, useUpdateOrganisationMutation } from "src/api/OrganisationApi";
import { getModuleList } from "src/common/Modules";
import XMark from "@impulso/common/Icons/XMark";
import Plus from "@impulso/common/Icons/Plus";
import CheckMark from "@impulso/common/Icons/CheckMark";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { useForm } from "@mantine/form";
import { OrganisationId } from "src/UserProfile";
import { BrandInput } from "./CreateOrganisation";

const initialValues = {
    id: "" as OrganisationId,
    rootOrgId: "",
    rootOrgName: "",
    modules: [],
    name: "",
    includeVat: false,
    currency: "SEK",
    swedishOrganisationNumber: "",
    isRetailer: false,
    isStore: false,
    isSupplier: false,
    isRealEstate: false,
    contactEmail: "",
    contactName: "",
    contactPhone: "",
    pingKycStatus: "",
    pingMerchantId: "",
    pingTaxMerchantId: "",
    pingMandate: "",
    isActive: false,
    brands: []
} as OrganisationData;

export default function UpdateOrganisationPanel() {
    const [search, setSearch] = useState<{label: string, value: string} | undefined>(undefined);

    const [UpdateOrganisationPost, {data: uploadData, isError: updateError, status: updateStatus, reset}] = useUpdateOrganisationMutation();
    const {data, isError: getError, status: getStatus} = useGetOrganisationQuery({query: {orgId: search?.value as OrganisationId}}, { skip: search?.value === undefined});

    const form = useForm<OrganisationData>({
        validateInputOnBlur: true,
        initialValues
    });

    useEffect(() => {
        if (data?.content != null && getStatus === QueryStatus.fulfilled) {

            //we don't want to set any form value to undefined because it will give us a warning
            const organisation: OrganisationData = Object.keys(data.content).reduce((cur: any, sKey)=> {
                const key: keyof OrganisationData = sKey as keyof OrganisationData;
                cur[key] = data.content![key] ?? initialValues[key];
                return cur;
            }, {});
            form.setValues({...organisation});
        }
    }, [data, getStatus]);

    const organisation = form.values;
    const isBlocked = !organisation.id;
    const loading = updateStatus === "pending" || getStatus === "pending";

    function onSubmit(event: FormEvent) {
        event.preventDefault(); 
        const request = Object.keys(organisation).reduce((cur: any, sKey)=> {
            const key: keyof OrganisationData = sKey as keyof OrganisationData;
            if (organisation[key] !== "") {
                cur[key] = organisation[key] as any;
            }
            return cur;
        }, {} as OrganisationData);
        UpdateOrganisationPost({query: {orgId: organisation.id!}, body: request});
    }

    return (
        <ReportFoldout title="Update Organisation">
            <div className="">
                <div className="w-[50%] px-4 pt-4">   
                    <SearchOrganisationField label="Organisation" currentOrg={search?.label ?? ""} clearSearch={() => {setSearch(undefined); form.reset(); reset();}} fetchOrganisation={(label: string, value: OrganisationId) => {setSearch({label, value})}}  />
                </div>
                
                <form className="py-4 mt-4 px-4 relative border-t" onSubmit={onSubmit}>
                    <div className="grid grid-cols-2 gap-4 pb-4">
                        <div className="flex flex-col gap-4">
                                <p className={(isBlocked ? 'hidden' : '') + ' text-sm text-gray-500'}>ID: {organisation.id}</p>
                                <TextInput label="Organisation Name" disabled={isBlocked} styles={dropdownStyling} {...form.getInputProps("name")} />
                                <SearchOrganisationField disabled={isBlocked} label="Root Organisation" currentOrg={organisation.rootOrgName ?? ""} clearSearch={() => form.setValues({rootOrgId: "" as OrganisationId, rootOrgName: ""})} fetchOrganisation={(label, value) => form.setValues({rootOrgId: value, rootOrgName: label})} />
                                <SearchOrganisationField disabled={isBlocked} label="Parent Organisation" currentOrg={organisation.parentOrgName ?? ""} clearSearch={() => form.setValues({parentOrgId: "" as OrganisationId, parentOrgName: ""})} fetchOrganisation={(label, value) => form.setValues({parentOrgId: value, parentOrgName: label})} />
                                <TextInput disabled={isBlocked} label="Swedish Organisation Number" styles={dropdownStyling} {...form.getInputProps("swedishOrganisationNumber")} />
                            <div>
                                <p className="text-sm">Organisation Type</p>
                                <div className="grid grid-cols-4 justify-between gap-8 border border-gray-400 p-2 whitespace-nowrap">
                                    <Checkbox label="Supplier" disabled={isBlocked} styles={dropdownStyling} {...form.getInputProps("isSupplier", {type: "checkbox"})} />
                                    <Checkbox label="Retailer" disabled={isBlocked} styles={dropdownStyling} {...form.getInputProps("isRetailer", {type: "checkbox"})} />
                                    <Checkbox label="Store" disabled={isBlocked} styles={dropdownStyling} {...form.getInputProps("isStore", {type: "checkbox"})} />
                                    <Checkbox label="Real Estate" disabled={isBlocked} styles={dropdownStyling} {...form.getInputProps("isRealEstate", {type: "checkbox"})} />
                                </div>
                            </div>
                            {form.values.isSupplier && <div>
                                <BrandInput form={form}/>
                            </div>}
                            <div className="flex grid grid-cols-2 gap-4">
                                <Select label="Currency" styles={dropdownStyling} {...form.getInputProps("currency")}
                                    placeholder="SEK"
                                    data={['SEK', 'NOK', 'DKK', 'EUR']}
                                    disabled={isBlocked}
                                />
                                <Checkbox disabled={isBlocked} label="Include VAT" className="mt-8" styles={dropdownStyling} {...form.getInputProps("includeVat", {type: "checkbox"})} />
                            </div>
                            <div>
                                <Checkbox disabled={isBlocked} label="Active" styles={dropdownStyling} {...form.getInputProps("isActive", {type: "checkbox"})} />
                            </div>
                        </div>
                        <div className="text-sm flex flex-col">
                            <p>Modules</p>
                            <div className="border grow overflow-y-auto max-h-[456px]">
                                { matchModuleLists(organisation?.modules ?? [], isBlocked ? [] : getModuleList(organisation?.isSupplier ?? false, (organisation?.isRetailer ?? false) || (organisation?.isStore ?? false))).map((m, index) => 
                                    <div key={m.label + "_" + index} className={"group py-2 gap-4 items-center flex px-4 hover:bg-brand-200 " + (!m.has ? 'bg-gray-200 ' : '')}>
                                        <div className="group-hover:block hidden hover:cursor-pointer" onClick={() => form.setValues({modules: toggleModule(organisation!.modules!, m.label)})}>{m.has ? <XMark /> : <Plus />}</div>
                                        {m.label}{(m.unauthorized ? <span className="font-semibold ml-auto">Unauthorized</span>: '')}
                                    </div>
                                ) }
                            </div>
                        </div>
                        <div className="col-span-2 flex flex-col gap-4">
                            <Divider orientation="horizontal" label="Contact Information" />
                            <div className="flex gap-4 grid grid-cols-4">
                                <TextInput disabled={isBlocked} label="Contact Email" styles={dropdownStyling} value={organisation?.contactEmail ?? ""} onChange={ event => form.setValues({contactEmail: event.currentTarget.value.replaceAll(' ', '')}) } />
                                <TextInput disabled={isBlocked} label="Contact Name" styles={dropdownStyling} {...form.getInputProps("contactName")} />
                                <TextInput disabled={isBlocked} label="Contact Phone" styles={dropdownStyling} {...form.getInputProps("contactPhone")} />
                            </div>
                            <Divider orientation="horizontal" label="Ping Payment" />
                            <div className="flex gap-4 grid grid-cols-4 text-sm">
                                <div>
                                    <p className="mt-[1px]">KYC Status</p>
                                    <p className={"mt-[1px] border border-gray-300 flex gap-2 items-center h-[36px] py-2 pl-4 " + (isBlocked ? 'bg-gray-100 ' : '') + (organisation?.pingKycStatus === 'Signed' ? 'bg-confirmation-100' : '')}>{organisation?.pingKycStatus === 'Signed' ? <CheckMark /> : <></>}{isBlocked ? '' : organisation?.pingKycStatus ?? "NOT FOUND"}</p>
                                </div>
                                <TextInput disabled={isBlocked} label="Merchant ID" styles={dropdownStyling} {...form.getInputProps("pingMerchantId")} />
                                <TextInput disabled={isBlocked} label="Tax Merchant ID" styles={dropdownStyling} {...form.getInputProps("pingTaxMerchantId")} />
                                <TextInput disabled={isBlocked} label="Mandate ID" styles={dropdownStyling} {...form.getInputProps("pingMandate")} />
                            </div>
                        </div>
                    </div>
                    <PrimaryButton label="Update" disabled={isBlocked} extraStyle="w-min" margin="ml-auto mt-auto" padding="py-2 px-8" type="submit" />
                    { loading && <div className="absolute inset-0 bg-white opacity-50"/> }
                    { loading && <div className="absolute flex inset-0 items-center justify-center"><Loader className="p-2" />Loading...</div> }
                </form>
            </div>
            {updateStatus === QueryStatus.uninitialized && <CreateBanner data={data} status={getStatus} isError={getError} />}
            {updateStatus !== QueryStatus.uninitialized && <CreateBanner data={uploadData} status={updateStatus} isError={updateError} />}
        </ReportFoldout>
    );
}

function matchModuleLists(a: string[], b: string[]) {
    const matches = a.filter(a => b.includes(a));
    const noHave = b.filter(b => !a.includes(b));
    const unauthorized = a.filter(a => !b.includes(a));
    var items: MatchItem[] = [];
    items = items.concat(unauthorized.map(m => {return {label: m, has: true, unauthorized: true}}));
    items = items.concat(matches.map(m => {return {label: m, has: true, unauthorized: false}}));
    items = items.concat(noHave.map(m => {return {label: m, has: false, unauthorized: false}}));

    return items;
}

function toggleModule(modules: string[], toggledModule: string) {
    var hasModule = modules.includes(toggledModule);
    if (hasModule) {
        return modules.filter(m => m !== toggledModule);
    } else {
        return modules.concat([toggledModule]);
    }
}

interface MatchItem {
    label: string,
    has: boolean,
    unauthorized: boolean
};